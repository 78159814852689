import Navbar from './Navbar';
import Home from './Home';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useState, useCallback } from 'react';
import SideBar2 from "./sidebar2";

import useWindowDimensions from './useWindowDimensions';
//import Create from './Create';
//import BlogDetails from './BlogDetails';
import Company from './Company';
import NotFound from './NotFound';
import AedcGallery from './AedcGallery';
import ArtisticDirector from './ArtisticDirector';
import WordsOnAThread from './WordsOnAThread';
import Collaborators from './Collaborators';
import Contact from './Contact';

const useCurrentPath = () => {
  const location = useLocation();
  return(location.pathname)
};

function App() {
  //const location = useLocation;
  //console.log(location);

  const {width : windowWidth, height : windowHeight} = useWindowDimensions();
  //const [menuDimensions, setMenuDimensions] = useState({width: 10, height: 10});
  const [{width: menuWidth, height: menuHeight}, setMenuDimensions] = useState({width: 10, height: 10});
  const menuBreakPoint = (windowWidth < 800);
  //console.log(menuBreakPoint);
  let siteBackground = 'url(/images/artistedge-background.jpg), linear-gradient(45deg, rgb(180, 205, 228) 0%, rgba(255,255,255) 100%)';

  const handleMenuChange = useCallback((menuDims) => {
      setMenuDimensions(menuDims);
    },
    [],
  );

  const currentPath = useCurrentPath();
  switch (currentPath) {
    case "/company":
      siteBackground = 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/artistedge-background.jpg)';
      break;    
    case "/collaborators":
      siteBackground = 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75)), url(/images/artistedge-background.jpg)';
      break;     
    case "/contact":
      siteBackground = 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75)), url(/images/artistedge-background.jpg)';
      break;
    case "/aedc-gallery":
      siteBackground = 'linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(/images/artistedge-background.jpg)';
      break;
    default: 
      siteBackground = 'url(/images/artistedge-background.jpg), linear-gradient(45deg, rgb(180, 205, 228) 0%, rgba(255,255,255) 100%)';
  }

  //console.log("App Screen Size: ", windowWidth, " x ", windowHeight);
  return (
    
      <div id="App" className="app" style={{ 
        backgroundImage: siteBackground, 
        backgroundPosition: 'top',
        //minHeight: 100%,
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
        //height: '220vw',
        width: '100vw',
        //backgroundBlendMode: siteBackgroundBlendMode,
        backgroundColor: 'black',
        //filter: 'brightness(50%)',
      }}>
        {menuBreakPoint ? <SideBar2 pageWrapId={"page-wrap"} outerContainerId={"App"} /> : <Navbar handleMenuChange={handleMenuChange} windowWidth={windowWidth} />}
        <div id="page-wrap" className="content" style={{
          padding: '0 0 0 0',
          margin: '0 0 0 0'
        }}>
          <Routes>
            <Route path="/" element={<Home windowWidth={windowWidth} windowHeight={windowHeight} menuWidth={menuWidth} menuHeight={menuHeight}/>} />
            <Route path="/company" element={<Company />} />
            <Route path="/aedc-gallery" element={<AedcGallery />} />
            <Route path="/artistic-director" element={<ArtisticDirector />} />
            <Route path="/words-on-a-thread" element={<WordsOnAThread />} />
            <Route path="/collaborators" element={<Collaborators />} />
            <Route path="/contact" element={<Contact />} />
            {/*<Route path="/create" element={<Create />} />*/}
            {/*<Route path="/blogs/:id" element={<BlogDetails />} />*/}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
  );
}

export default App;
