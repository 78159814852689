import React from "react";
import Column from "./Column";
import Grid from "./Grid";

import styled from "styled-components";


const Container = styled.div`
	display: flex;
	flex-flow: column wrap;
	img {
		max-width: 100%;
        size: 100% auto;
	}
	font-size: 1.3rem;
	h3 {
		font-size: 2.1rem;
        color: black;
		margin-bottom: 0px;
		padding-left: 15px;
		padding-right: 15px;
	}
	h4 {
		font-size: 1.5rem;
		margin-bottom: 0px;
		padding-left: 15px;
		padding-right: 15px;
	}
`;

const Bio = styled.div`
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(140, 225, 225, 1) 50%, rgba(255,255,255,1) 100%);
	flex-flow: column wrap;
	min-height: 400px;
	font-size: 1.0rem;
    text-align: justified;
    text-align-last: left;

    p {
        padding-left: 2.0rem;
        padding-right: 2.9rem;
        margin-bottom: 1.0rem;
        color: black;
    }

	h3 {
        font-family: RieslingRegular;
        text-rendering: optimizeLegibility;
        font-weight: 300;
        color: black;
		font-size: 4.0rem;
		margin-bottom: 0px;
        text-align: center;
        text-align-last: center;
	}
	h4 {
		font-size: 1.4rem;
        font-weight: 300;
        color: black;
		margin-bottom: 1.0rem;
        text-align: center;
        text-align-last: center;
	}
`;

export default function ArtisticDirector() {

    return(
        <Grid>
			<Column className="m">
                <Container>
                    <img src='/images/jeanninebio.jpg' alt="Bio">
                    </img> 
				</Container>
			</Column>
			<Column className="m">
                <Bio>
                    <h3>Jeannine Charles</h3>
                    <h4>Artistic Director & Choreographer</h4>
                    <p>A child that was full of joy, Jeannine Charles started dancing (and singing) as soon as her body would let her!  Due to the flurry of activity (usual centered right under her mother's feet) Jeannine was placed into her first dance class at age 2.  Early training included years of ballet with Kip Watson, former New York City ballet dancer at the School of Performing Arts in Orlando, FL, and trained in Belgium at Mercador Ballet School affiliated with the London Royal Ballet.  At 16 she won a national title for tap dance at Caeser's Palace in Las Vegas after winning 2nd in Vancouver, Canada.  One way to take care of those antsy feet...stick them in a pair of tap shoes.</p>
                    <p>Although Ms.Charles received a college scholarship from an Electronics company, before choosing any other class, her first impulse was to register for  Dance Composition with Joan Skinner who opened her eyes to the deeper aspects of choreography and the fulfillment of bringing feelings and ideas to life through movement.  This class, more than any other, spoke to her on such a deep level that the only degree she could entertain from that point on was one in dance.</p>
                    <p>During her college career, she was accepted for the 6 week intensive at Harvard, the Limon Workshop at San Jose State, and began her professional career when she was hired to dance at musician William O'Farrell's concert in Seattle receiving critical acclaim in the local paper (O'Farrell (Clarinetist) was one of the first musicians to play with the Dave Brubeck Quartet in New York) as well as working under contract for Richard Jessup's Jazz Dance company as a soloist.  She graduated from the University of Washington with a B.A.  in Performance/Choreography receiving Choreography of the Year Awards 2 years in a row as well as the National Dance Association's Dance Major of the Year Award. </p>
                    <p>After U.W., Jeannine relocated to the Bay Area and has performed with sjDanceCo (company choreographer&dancer), Company Chaddick/SF, Nina Galin Music & Dance, Margaret Wingrove Dance Company, Dancing Sun, Jose Ibarra, Maria Basile, Gary Masters, Heather Cooper, Lisa Burnett and Ehud Krauss among many others.  She started her first company, VogtART in 1995 and evolved this into ArtistEDGE Dance Company.  Her work has been seen all over the Bay Area including Summerfest/SF, Pilot @ ODC Theatre, Choreographer's Collective (also the executive producer), Zohar's Dance Mosaic, LINK/SF, ChoreoAwards, Dancing Sun Presents... to name a few.  She has collaborated with different artists such as Martin Filiatrault to develop original drum music for her work, “Twisted 6” and Daniel Canogar, world renown Visual Multi-media Artist for a project at Villa Montalvo, Bih-Tau Sung in shows at Center for the Performing Arts,  Montgomery Theater and a site specific work in San Jose. She has also worked with Trey Knight, World Ranked artistic Roller Skater and Stilt dancer for projects in San Francisco, one of which involved a contortionist, a hoop and a stilt dancer at Acrosport.</p>
                    <p>Ms. Charles has taught at American Musical Theater, San Jose City College, Dancing Sun, San Jose State, Beresford Rec. and is currently teaching on Wednesday nights at 7:30p at Zohar in Palo Alto.</p>
                    <p>She created original dance sequences for Dr. Paul Davies’Opera, Carlota, directed by Daniel Helfgot, performed at the Mexican Heritage Plaza Theater in San Jose, February 15, 2015. </p>
                    <p>The company performed About:FACE, in sjDANCEco's Choreoproject at the San Jose State University Music Concert Hall in February of 2015 and at Mission Theater in San Francisco in their bi-annual Choreographer's Showcase in April, 2015.  Soliloquy premiered during National Dance Week and was developed as part of a newer piece titled, “Esperanza: ForeverME” a collaboration with transgender poet, Val Ge that was performed by the company in June of 2015 as invited guests of Natasha Carlitz Dance Ensemble in San Francisco.</p>
                    <p>In the Fall of 2015, Ms. Charles developed, UN:Ravelled, an exploration of our most extreme behaviorial tendencies (insomnia, OCD, depression, anger) trying to find a way to reconcile these divergent aspects into a functional being. The work premiered in Mission College Dance Company's show that December and subsequently at Zohar and sjDANCEco's Choreoproject in March, 2016.</p>  
                    <p>In January of 2016, a collaboration with Daniel Helfgot (an accomplished Opera Director) and Michel  Singher (highly esteemed conductor and founder of Espressivo) led Ms. Charles to create the choreography for Stravinsky's "A Soldier's Tale".  A truly collaborative effort, the choreography was inspired by the vision of Mr. Singher and the dramatic subtlety and focused direction of Mr. Helfgot.  The performance was held at the Colligan Theater in Santa Cruz in April, 2016.</p>
                    <p>Toward the end of April,  she premiered a work-in-progress at Dance Mission in San Francisco titled,  [frames] and is currently developing the work for ArtistEDGE's first full evening of work at Zohar Dance Studio Theater on August 27th. [frames] is about creating ideas from memories, searching the deep root of a memory and extracting the feelings it invokes, and it's impact on our personality. The vehicle of reminder?  A paper plane.</p>
                </Bio>
			</Column>
        </Grid>
    )
}
/*
export default function ArtisticDirector() {
    return(
    <div className="artistic-director" style={{
        backgroundColor: 'linear-gradient(45deg, rgba(73, 133, 189, 0.41) 0%, rgba(255,255,255,1) 100%)',
        height: '50wv'
        }}>
        <h1>Collaborators</h1>
        <img src='/images/jeanninebio.jpg' alt="Bio" style={{width:'50%'}}>
        </img> 
    </div>
    )
} */

/*
<div className="artistic-director" style={{
    backgroundColor: 'linear-gradient(45deg, rgba(73, 133, 189, 0.41) 0%, rgba(255,255,255,1) 100%)',
    height: '200wv'
    }}>
    <col>
        <img src='url(/images/jeanninebio.jpg)' alt="Bio" style={{
            width: '50wv',
            }}>
        </img>
    </col>
    <col>
        <p style={{
            paddingTop: '1rem',
            color: '#ffffff',
            //backgroundColor: 'rgba(0, 0, 0, 1)', //'#000',
            fontSize: '1vw', //'1rem',
            textAlign: 'center',
            textJustify: 'inter-word',
            }}>Jeannine Charles
            </p>
    </col>
</div> */