import Vimeo from '@u-wave/react-vimeo';
//import ceil from Math;

const Home = ({windowWidth, windowHeight, menuWidth, menuHeight}) => {
  //const { error, isPending, data: blogs } = useFetch('http://localhost:8000/blogs')
  //console.log("Screen Size: ", windowWidth, " x ", windowHeight);
  //console.log("Menu Size: ", menuWidth, " x ", menuHeight);
  const backgroundImageWidth = 1920;  // When loading from firebase these should be replaced with values in DB
  const backgroundImageHeight = 1164;
  const videoOffsetFromBackground = 10;
  const videoVerticalOffsset = (windowWidth > 799) ? Math.ceil(backgroundImageHeight / backgroundImageWidth * windowWidth + videoOffsetFromBackground - menuHeight) :
                                                     Math.ceil(backgroundImageHeight / backgroundImageWidth * windowWidth + videoOffsetFromBackground);
  //console.log(videoVerticalOffsset);

  return (
    <div className="home" 
    style={{
        paddingTop: `${videoVerticalOffsset}px`,
        //paddingTop: `total total-`, //{`/blogs/${videoVerticalOffsset}`},
        paddingLeft: '15vw',
        width: '85vw'
      }}>      
      <Vimeo 
        video="245095318" 
        responsive
        showTitle={false}
        showPortrait={false}
        showByline={false}
        //autoplay={true}
        background={true}
        controls={true}
        playsInline={false} // will auto-launch to full screen on mobile device
      />
    </div>                                                                                        
  );
}

export default Home;
