import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Reaptcha from 'reaptcha';
 
export const Contact = () => {
  const form = useRef();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [invisibleCaptcha, setInvisibleCaptcha] = useState(null);
  const [messageSentNotification, setMessageSentNotification] = useState((<span>&nbsp;</span>)); 
  const spacer = (<span>&nbsp;</span>);

  const onVerify = recaptchaResponse => {
    setCaptchaVerified(true);
    //console.log("Verified");
  }

  const sendEmail = (e) => {
    e.preventDefault();

    const email = form.current.reply_to.value;
    const isValidEmail = email => {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(String(email).toLowerCase());
    };

    const valid_email = isValidEmail(email);
    /*
    const printForm = form.current.from_name.value;
    console.log(printForm);
    const printForm2 = form.current.reply_to.value;
    console.log(printForm2);
    const printForm3 = form.current.message.value; 
    console.log(printForm3);
    */
    if (!valid_email) {
      alert('Please enter a valid e-mail address.');
    } else if (!form.current.from_name.value) {
      alert('Please provide a contact name.');
    } else if (!form.current.message.value) {
      alert('Please provide a message.');
    } else {
  
    emailjs.sendForm('service_1czvat3', 'template_1pp8k8y', form.current, 'oNm-m0oyt3LKZKj0D')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    }
    setCaptchaVerified(false);
    document.getElementById("contact-form").reset();
    invisibleCaptcha.reset();
    setMessageSentNotification("Message sent at: " + Date().toLocaleString());
  };

  return (
    <form id="contact-form" ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="from_name" />
      <label>Email</label>
      <input type="email" name="reply_to" />
      <label>Message</label>
      <textarea name="message" />
      <Reaptcha 
        ref={e => setInvisibleCaptcha(e)}
        sitekey="6Lc8g2MiAAAAACzTlSMxyrPtODta6bt09NcGOUG9"
        onVerify={onVerify} 
        theme='dark'
        badge='bottomleft'
      />
      <label>{messageSentNotification}</label>
      <input type="submit" disabled={!captchaVerified} value="Send" />
      <p>Thank you so much for taking the time to visit our site and to contact us!  We are a passionate and dedicated group of artists who greatly appreciate your feedback. </p>
      <p>Sincerely,</p>
      <p className="signature">Jeannine Charles</p>
    </form>
  );
};

export default Contact;

/*  Invisible captcha - would run but did not seeem to generate a onVerify function call - difficult to debug 
import React, { Fragment, useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import styled from "styled-components";
//import ReCAPTCHA from "react-google-recaptcha";
import Reaptcha from 'reaptcha';

const sleep = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);
 
export const Contact = () => {
  const form = useRef();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const [invisibleCaptcha, setInvisibleCaptcha] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    const email = form.current.reply_to.value;
    const isValidEmail = email => {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(String(email).toLowerCase());
    };

    const valid_email = isValidEmail(email);
    
    const printForm = form.current.from_name.value;
    console.log(printForm);
    const printForm2 = form.current.reply_to.value;
    console.log(printForm2);
    const printForm3 = form.current.message.value; 
    console.log(printForm3);

    //await sleep(2*1000);
    
    if (!valid_email) {
      alert('Please enter a valid e-mail address.');
    } else if (!form.current.from_name.value) {
        alert('Please provide a contact name.');
    } else if (!form.current.message.value) {
        alert('Please provide a message.');
    } else {
    
    emailjs.sendForm('service_1czvat3', 'template_1pp8k8y', form.current, 'oNm-m0oyt3LKZKj0D')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    }
  };

  const onVerify = recaptchaResponse => {
    //setCaptchaVerified(true);
    console.log("Verified");
    setCaptchaVerified(true);
    sendEmail();
  }

  const onLoad = () => {
    //setCaptchaVerified(true);
    console.log("Loaded");
    if (captchaVerified) {
      console.log("Verified prior to loading");
    }
    setCaptchaLoaded(true);
  }

  
  const startCaptcha= () => { 
    console.log("Starting Captcha");
    if (captchaLoaded) {
      invisibleCaptcha.execute();
    }
    if (captchaVerified) {
      console.log("Verified - should result in email");
    }
  }

  //      <form id="contact-form" ref={form} onSubmit={invisibleCaptcha.execute()}>
  //<form id="contact-form" ref={form}>
  return (
    <Fragment>
      <Reaptcha 
        ref={e => setInvisibleCaptcha(e)}
        sitekey="6Lc8g2MiAAAAACzTlSMxyrPtODta6bt09NcGOUG9"
        onVerify={onVerify}
        onLoad={onLoad}
        size="invisible"
        />
      <form id="contact-form" ref={form} onSubmit={startCaptcha}>
        <label>Name</label>
        <input type="text" name="from_name" />
        <label>Email</label>
        <input type="email" name="reply_to" />
        <label>Message</label>
        <textarea name="message" />
        <input type="submit" value="Send" />
        <p>Thank you so much for taking the time to visit our site and to contact us!  We are a passionate and dedicated group of artists who greatly appreciate your feedback. </p>
        <p>Sincerely,</p>
        <p className="signature">Jeannine Charles</p>
      </form>
    </Fragment>
  );
};

export default Contact;
*/
