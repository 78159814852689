export const photos = [
  {
    src: '/images/gallery/1.jpg',
    width: 3,
    height: 5
  },
  {
    src: '/images/gallery/9.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/images/gallery/8.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/images/gallery/11.jpg',
    width: 4,
    height: 4
  },
  {
    src: '/images/gallery/10.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/images/gallery/6.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/images/gallery/7.jpg', 
    width: 4, 
    height: 4
  },
  {
    src: '/images/gallery/5.jpg',
    width: 4,
    height: 4
  },
  {
    src: '/images/gallery/2.jpg',
    width: 4,
    height: 4
  },
  {
    src: '/images/gallery/12.jpg',
    width: 4,
    height: 4
  },
  {
    src: '/images/gallery/14.png',
    width: 4,
    height: 4
  },
  {
    src: '/images/gallery/13.jpg',
    width: 4,
    height: 4
  },
  {
    src: '/images/gallery/3.jpg',
    width: 4,
    height: 4
  },
  {
    src: '/images/gallery/4.jpg',
    width: 5,
    height: 4
  },
];

/* OLD
export const photos = [
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/941-4.jpg",
    width: 3,
    height: 5
  },
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/1010-img3288.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/1008-img0021jpg.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/943-hacktavists-1.jpg",
    width: 4,
    height: 4
  },
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/942-a-tension-2.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/1006-img0402-1.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/1007-img0050jpg-1.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/1004-img0067jpg.jpg",
    width: 4,
    height: 4
  },
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/1001-2.jpg",
    width: 4,
    height: 4
  },
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/944-imgp5532jpg.jpg",
    width: 4,
    height: 4
  },
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/1005-jeannineandzann-1.png",
    width: 4,
    height: 4
  },
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/947-megjen-1.jpg",
    width: 4,
    height: 4
  },
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/1002-3.jpg",
    width: 4,
    height: 4
  },
  {
    src: "https://www.artistedge.net/Gallery%20-%20Artistedge_files/1003-232516844449efa95a206b.jpg",
    width: 4,
    height: 4
  },
];
*/
