export default function Collaborators() {
    return(
        <div className="collaborators"style={{
            width: '100vw',
            height: '100vh',
            //backgroundColor: 'rgba(0, 0, 0, 0.75)', //'#000',
          }}>
            <h1>Ms. Charles has had the priviledge of working with:</h1>
            <ul className="collaborators-list">
                <p><a href="https://espressorch.org/about.php">Michel Singher - Conductor, Espressivo</a></p>
                <p><a href="http://www.danielhelfgot.com/">Daniel Helfgot - Renown Opera Director</a></p>
                <p><a href="http://www.daviespaul.com/biography.html">Dr. Paul Davies - Classical Composer</a></p>
                <p><a href="http://www.danielcanogar.com/">Daniel Canogar - Visual Multimedia Artist</a></p>
                <p><a href="https://www.fandalism.com/drumbiker">Martin Filiatrault - Master Drummer</a></p>
                <p>Gordon Charles - &nbsp;Lighting Engineer Technologist&nbsp;</p>
                <p>The Band, FEJU: &nbsp;Mike Fair, Saaku Sar, Tom Goering, Patter Hatfield&nbsp;</p>
                <p><a href="https://www.gototalentagency.com/index.php/trey-knight">Trey Knight - founder of Stilt World &amp; Artistic Roller Skating Champion</a></p>
            </ul>
        </div>

    )
}